<template>
  <div class="container-fluid">
    <div class="d-flex align-items-center text-center justify-content-center m-2 pt-2">
      <h1>{{ $t('job.my_account.title') }}</h1>
      <span class="badge badge-pill badge-primary ml-2">BETA</span>
    </div>

    <div class="d-flex justify-content-center justify-content-md-end mb-4">
      <b-dropdown :text="$t('job.my_account.button.filter') + $t('job.status')[this.status]" class="mr-2">
        <b-dropdown-item @click="handleFilter(-1)">{{ $t('job.status')[-1] }}</b-dropdown-item>
        <b-dropdown-divider/>
        <b-dropdown-item @click="handleFilter(0)">{{ $t('job.status')[0] }}</b-dropdown-item>
        <b-dropdown-item @click="handleFilter(1)">{{ $t('job.status')[1] }}</b-dropdown-item>
        <b-dropdown-item @click="handleFilter(2)">{{ $t('job.status')[2] }}</b-dropdown-item>
        <b-dropdown-item @click="handleFilter(3)">{{ $t('job.status')[3] }}</b-dropdown-item>
        <b-dropdown-item @click="handleFilter(4)">{{ $t('job.status')[4] }}</b-dropdown-item>
        <b-dropdown-item @click="handleFilter(5)">{{ $t('job.status')[5] }}</b-dropdown-item>
      </b-dropdown>
      <router-link :to="{ name: 'task_or_job', params: { referrer: 'my_jobs' } }" class="btn btn-primary">{{ $t('job.my_account.button.create') }}</router-link>
    </div>

    <template v-if="loaded">
      <div v-if="items.length < 1">
        <div v-if="this.status === -1">
          <i class="fa-solid fa-5x fa-person-circle-plus mb-3"></i>
          <p>{{ $t('job.my_account.no_jobs_created') }}</p>
        </div>
        <div v-else>
          <i class="fa-solid fa-5x fa-person-circle-exclamation mb-3"></i>
          <p>{{ $t('job.my_account.no_results') }}</p>
        </div>
      </div>
      <table class="table table-striped table-bordered add-manage-table bg-white" v-else>
        <thead>
        <tr>
          <th>{{ $t('job.my_account.headers.title') }}</th>
          <th>{{ $t('job.my_account.headers.budget') }}</th>
          <th>{{ $t('job.my_account.headers.expired_date') }}</th>
          <th>{{ $t('job.my_account.headers.offer') }}</th>
          <th>{{ $t('job.my_account.headers.status') }}</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in getItems" :key="index" :class="{ 'removed': item.hasOwnProperty('removed') }" class="mb-4">
          <td :data-title="$t('job.my_account.headers.title')">
            <span v-if="item.status !== 2">
              {{ item.title }}
            </span>
            <router-link v-else :to="{ name: 'jobs_view', params: { hash: item.hash }}" class="text-dark">
              {{ item.title }}
            </router-link>
          </td>
          <td :data-title="$t('job.my_account.headers.budget')">
            <template v-if="item.price_from === null || item.price_to === null">{{ $t('job.my_account.no_price_set') }}</template>
            <template v-else>${{ item.price_from.toFixed(2) }} / ${{ item.price_to.toFixed(2) }}</template>
          </td>
          <td :data-title="$t('job.my_account.headers.expired_date')">
            {{ date(item.date_expires) }}
          </td>
          <td :data-title="$t('job.my_account.headers.offer')">
            <div class="d-flex align-items-center flex-md-column">
              <div>
                {{ item.job_offer.length }}
              </div>
              <b-button v-if="item.job_offer.length && [2,3,4].includes(item.status)" size="sm" variant="primary" class="mt-md-2 view-offers-button" @click="$router.push({ name: 'jobs_view', params: { hash: item.hash }, hash: '#offer-messages' })">
                {{ $t('job.my_account.button.view_offers') }}
                <b-badge v-if="getNewOffers().hashes.includes(item.hash)" class="rounded-circle ml-1 text-primary" variant="light">{{ getNewOffers().hashes.filter(hash => hash === item.hash).length }}</b-badge>
              </b-button>
            </div>
          </td>
          <td :data-title="$t('job.my_account.headers.status')" :class="getStatusClass(item.status)">
            {{ $t(`job.status.${item.status}`) }}
          </td>
          <td :data-title="$t('job.my_account.headers.action')">
            <b-dropdown :text="$t('job.my_account.button.select_action')" variant="outline-dark">
              <b-dropdown-item :disabled="item.status !== 2 && item.status !== 3 && item.status !== 4" @click="$router.push({ name: 'jobs_view', params: { hash: item.hash }})">{{
                  $t('job.my_account.button.view')
                }}
              </b-dropdown-item>
              <b-dropdown-item @click="$router.push({ name: 'job-edit', params: { hash:  item.hash }})">{{ $t('job.my_account.button.edit') }}</b-dropdown-item>
              <b-dropdown-item @click="rePostJob(index)" :disabled="item.status !== 4">{{ $t('job.my_account.button.repost') }}</b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item @click="removeJob(index)">{{ $t('job.my_account.button.remove') }}</b-dropdown-item>
            </b-dropdown>
          </td>
        </tr>
        </tbody>
      </table>
    </template>
    <template v-else>
      <div class="mx-auto my-5">
        <b-spinner large label="Large Spinner"></b-spinner>
        <div class="mt-2">{{ $t('job.my_account.loading') }}</div>
      </div>
    </template>

    <div class="d-flex justify-content-center mt-4">
      <Pagination v-if="pagination.total > pagination.per_page" :per-page="pagination.per_page" :current-page="pagination.page" :total="pagination.total"
                  v-on:paginationUpdate="getJobs"/>
    </div>

  </div>

</template>

<script>
import ApiService from '../../../apiService'
import Pagination from '../../../components/Global/Pagination'
import { mapGetters } from 'vuex'

export default {
  name: 'ViewPage',
  components: { Pagination },
  data () {
    return {
      loaded: false,
      items: [],
      status: -1,
      pagination: {
        per_page: 10,
        page: 1,
        total: 0
      }
    }
  },
  created () {
    this.getJobs()
  },
  mounted () {
    this.$tracker.tagEvent('myJobsView', {})
  },
  methods: {
    getJobs (page = 1) {
      this.loaded = false
      ApiService.getOwnJobs(this.status, page).then((resp) => {
        this.pagination = resp.data.data.pagination
        this.items = resp.data.data.results
        this.loaded = true
      })
    },
    handleFilter (value) {
      this.status = value
      this.getJobs(1)
    },
    removeJob (index) {
      const item = this.items[index]
      this.$swal({
        title: this.$t('job.my_account.modal_remove.title'),
        text: item.title,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('job.my_account.modal_remove.confirm_button'),
        cancelButtonText: this.$t('job.my_account.modal_remove.cancel_button'),
        showCloseButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return ApiService.removeJob(item.hash).then(() => {
            return true
          }).catch(() => {
            this.$swal.showValidationMessage(
              '<p class="mb-0">' + this.$t('job.my_account.modal_remove.error_message') + '</p>'
            )
            return false
          })
        }
      }).then((result) => {
        if (result.value !== '' && result.dismiss == null) {
          this.items[index].removed = true
          this.$forceUpdate()
        }
      })
    },
    rePostJob (index) {
      const item = this.items[index]
      this.$swal({
        title: this.$t('job.my_account.modal_repost.title'),
        text: item.title,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('job.my_account.modal_repost.confirm_button'),
        cancelButtonText: this.$t('job.my_account.modal_repost.cancel_button'),
        showCloseButton: true,
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          return ApiService.repostJob(item.hash).then(() => {
            return true
          }).catch(() => {
            this.$swal.showValidationMessage(
              '<p class="mb-0">' + this.$t('job.my_account.modal_remove.error_message') + '</p>'
            )
            return false
          })
        }
      }).then((result) => {
        if (result.value !== '' && result.dismiss == null) {
          this.items[index].status = 2
        }
      })
    },
    date (date) {
      if (date === null) {
        return this.$t('job.my_account.no_date_set')
      }
      const newDate = new Date(date)
      return newDate.toLocaleDateString()
    },
    getStatusClass (value) {
      switch (value) {
        case 1:
          return ''
        case 2:
          return 'text-success'
        case 3:
          return 'text-info'
        case 4:
          return 'text-warning'
        case 5:
          return 'text-danger'
      }
      return ''
    }
  },
  computed: {
    ...mapGetters(['getNewOffers']),
    getItems () {
      return this.items
    }
  }
}
</script>

<style scoped>
.removed {
  opacity: 0.2;
  pointer-events: none;
}

.offers-badge {
  font-size: 0.7rem;
  margin-top: -6px;
}

@media only screen and (max-width: 768px) {
  /* Force table to not be like tables anymore */
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
    background: unset !important;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    border: 1px solid #ccc;
    background: #FFFFFF !important;
  }

  table {
    border: none;
  }

  td {
    /* Behave like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50% !important;
    text-align: left;
    overflow-y: visible;
    font-size: 90%;
    width: unset;
    div {
      flex-direction: row !important;

      .view-offers-button {
        margin-left: 5px !important;
      }
    }
  }

  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 1em;
    height: 100%;
    left: 1em;
    width: 45%;
    padding-right: 10px;
    text-align: left;
    overflow-y: hidden;
    font-weight: bold;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  /*
  Label the data
  */
  td:before {
    content: attr(data-title);
    color: black !important;
  }
}
</style>
