<template>
  <b-modal ref="job-preview" id="job-preview" size="xl" centered class="p-0" :body-bg-variant="'gray'" title="Preview Job" ok-only>
    <div class="p-4">
      <job-card :job="job" :can-make-offer="false" :is-seller="false" :display-job-button="false" :is-preview="true"/>
    </div>
  </b-modal>
</template>

<script>
import JobCard from '../../Job/JobCard'

export default {
  name: 'PreviewJob',
  components: { JobCard },
  props: {
    job: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
::v-deep .job-scroll-description {
  overflow: auto;
  overflow-y: scroll;
  max-height: 265px;
}

.job-container {
  border-radius: 1.25rem;
}

::v-deep ul li {
  list-style-type: disc!important;
}

::v-deep ol li {
  list-style-type: auto!important;
}
</style>
