<template>
  <div class="bg-white">
    <div class="container pt-5 pb-5">
      <div class="row">
        <H1Tangerine :title="title" class="col-12"/>
      </div>
      <div class="row">
        <div class="col-12" v-if="jobFailed !== null">
          <div class="alert alert-danger">
            {{ jobFailed }}
          </div>
        </div>

        <div class="col-12 col-md-4 pb-3 d-md-block" v-bind:class="{'d-none': !displaySideMenu}">
          <SidebarLine :active-state="activeSection" :complete-state="completeState" :list="$t('job.create_edit.navigation')" @sidebar-line-li-clicked="switchSection" />
        </div>
        <div class="col-12 col-md-8 d-md-block" v-bind:class="{'d-none': displaySideMenu}">
          <div class="mt-2 px-2 text-left pb-3">
            <create-edit-section1 v-if="activeSection === 0" @job-create-edit-save="handleSubmit" :validation-failed="validationFailedResults" :job="job" />
            <create-edit-section2 v-if="activeSection === 1" @job-create-edit-save="handleSubmit" @job-create-edit-back="activeSection--" :validation-failed="validationFailedResults" :job="job" />
            <create-edit-section3 v-if="activeSection === 2" @job-create-edit-save="handleSubmit" @job-create-edit-back="activeSection--" :validation-failed="validationFailedResults" :job="job" />
            <create-edit-section4 v-if="activeSection === 3" @job-create-edit-save="successModal" @job-create-edit-back="activeSection--" :validation-failed="validationFailedResults" :job="job" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TouchEvent from '../../../utilities/touchEvent'
import H1Tangerine from '../../../components/Global/H1Tangerine'
import SidebarLine from '../../../components/Global/Navigation/SidebarLine'
import CreateEditSection1 from '../../../components/MyAccount/Job/CreateEditSection1'
import CreateEditSection2 from '../../../components/MyAccount/Job/CreateEditSection2'
import CreateEditSection3 from '../../../components/MyAccount/Job/CreateEditSection3'
import CreateEditSection4 from '../../../components/MyAccount/Job/CreateEditSection4'
import ApiService from '../../../apiService'

export default {
  name: 'CreateEdit',
  components: { CreateEditSection1, CreateEditSection2, CreateEditSection3, CreateEditSection4, SidebarLine, H1Tangerine },
  data () {
    return {
      title: null,
      displaySideMenu: 1,
      activeSection: -1,
      completeState: -1,
      validationFailedResults: {
        messages: [],
        fields: []
      },
      jobFailed: null,
      job: {},
      touchEvent: null
    }
  },
  async created () {
    if (this.$route.name === 'job-edit') {
      this.title = this.$t('job.create_edit.title.edit')
      await ApiService.getJob(this.$route.params.hash, true).then(async (resp) => {
        this.activeSection = 0
        this.job = resp.data.data
        this.processCheck()
        if (this.job.status === 5) {
          await ApiService.getJobNotes(this.job.hash).then((resp) => {
            this.jobFailed = resp.data.data.at(-1).message
          })
        }
      })
    } else {
      this.title = this.$t('job.create_edit.title.create')
      this.activeSection = 0
      this.processCheck()
    }
  },
  mounted () {
    document.addEventListener('touchstart', this.touchStart, { passive: false })
    document.addEventListener('touchend', this.touchEnd, { passive: false })
  },
  destroyed () {
    document.removeEventListener('touchstart', this.touchStart)
    document.removeEventListener('touchend', this.touchEnd)
    this.touchEvent = null
  },
  methods: {
    touchStart (event) {
      this.touchEvent = new TouchEvent(event)
    },
    touchEnd (event) {
      if (!this.touchEvent) {
        return
      }
      this.touchEvent.setEndEvent(event)
      if (this.touchEvent.isSwipeRight()) {
        this.displaySideMenu = 1
      } else if (this.touchEvent.isSwipeLeft()) {
        this.displaySideMenu = 0
      }

      this.touchEvent = null
    },
    switchSection (value) {
      this.displaySideMenu = 0
      this.activeSection = value
    },
    processCheck () {
      this.completeState = 0

      if (!this.job.hasOwnProperty('hash') && this.job.hash !== null) {
        return
      }

      if (this.job.title !== '' || this.job.description !== '') {
        this.completeState++
      }

      if (this.job.price_to !== null || this.job.price_from !== null) {
        this.completeState++
      }

      if (this.job.job_assets.length > 0) {
        this.completeState++
      }
    },
    successModal () {
      this.$swal({
        title: this.$t('job.create_edit.success_modal.title'),
        text: this.$t('job.create_edit.success_modal.description'),
        type: 'success',
        confirmButtonText: this.$t('job.create_edit.success_modal.confirm_button')
      })
    },
    async handleSubmit (value) {
      if (this.job.hasOwnProperty('hash') && this.job.hash !== null) {
        await ApiService.editJob(this.job.hash, value).then((resp) => {
          this.job = resp.data.data
          this.activeSection++
        }).catch((error) => {
          this.handleError(error)
        })
      } else {
        await ApiService.createJob(value).then((resp) => {
          this.job = resp.data.data
          this.activeSection++
        }).catch((error) => {
          this.handleError(error)
        })
      }
      this.processCheck()
    },
    handleError (error) {
      let validation = {
        messages: [],
        fields: []
      }

      if (error.response.status === 400) {
        const response = error.response.data.errors
        for (let key in response.children) {
          if (response.children[key].hasOwnProperty('errors')) {
            if (!Array.isArray(response.children[key].errors)) {
              response.children[key].errors = [response.children[key].errors]
            }

            response.children[key].errors.forEach((value) => {
              validation.fields.push(key)
              validation.messages.push(value)
            })
          }
        }
      } else if (error.response.status === 409) {
        validation.messages.push(error.response.data.error_message)
      }
      this.validationFailedResults = validation
      window.scrollTo(0, 0)
    }
  }
}
</script>
