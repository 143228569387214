<template>
  <div class="pr-5">
    <ul class="list-unstyled">
      <li v-on:click="liClicked(index)" v-for="(item, index) in list" :key="index" class="text-right position-relative"
          v-bind:class="{ 'text-primary active cursorLink': (active === index),
                          'completed cursorLink': (completeState > index),
                          'inactive': (index > active)}">
        <h3 class="font-weight-bold text-right">{{ item.title }}</h3>
        <p>{{ item.description }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SidebarLine',
  props: {
    list: {
      type: Array,
      required: true
    },
    activeState: {
      type: Number,
      required: false,
      default: 0
    },
    completeState: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data () {
    return {
      active: this.activeState,
      complete: this.completeState
    }
  },
  watch: {
    'activeState': function (val, oldVal) {
      if (val !== oldVal) {
        this.active = val
      }
    },
    'completeState': function (val, oldVal) {
      if (val !== oldVal) {
        this.complete = val
      }
    }
  },
  methods: {
    liClicked (index) {
      if (index <= this.complete) {
        this.$emit('sidebar-line-li-clicked', index)
      }
    }
  }
}
</script>

<style scoped>
li {
  padding: 10px;
}

li:before {
  content: '';
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #ffffff;
  border: 2px solid #D8D8D8;
  position: absolute;
  right: -22px;
  top: 0;
  transform: translateY(-40%);
  z-index: 20;
  margin-top: 25px
}

.active:before {
  border: 2px solid #44aae1;
}

.completed:before {
  content: '\2714\0020';
  background: #44aae1;
  border: 2px solid #44aae1;
  font-size: 8px;
  text-align: center;
  color: white;
}

li.inactive.completed:before {
  opacity: 0.5;
}

li:after {
  height: 100%;
  content: '';
  width: 2px;
  background: #D8D8D8;
  position: absolute;
  right: -15px;
  top: 0%;
  z-index: 1;
}

li:last-child:after {
  height: 25%
}

li:first-child:after {
  top: 25%;
}

.inactive h3, .inactive p {
  color: #6c757d;
  opacity: 0.5;
}

.cursorLink {
  cursor: pointer;
}
</style>
