<template>
  <!--verified-->
  <div class="d-flex flex-column align-items-center py-2 text-left" :class="[accountLimits ? 'verified hello' : 'verified mx-auto', accountType ? 'hello option ml-md-5 mb-3 mb-md-0 px-3' : '']">
    <div class="icon-shield mb-4 d-flex justify-content-center align-items-center">
      <img :src="require('@/assets/Connecting/shield-icon.svg')" width="36px">
    </div>
    <span class="mt-0">{{ $t('account_verified_block.subheader') }}</span>
    <h2 class="font-weight-bold mx-auto mb-3">{{ $t('account_verified_block.header') }}</h2>
    <hr class="w-75">
    <ol class="mt-2 p-0">
      <li class="d-flex justify-content-start align-items-center mb-2">
        <img :src="require('@/assets/Connecting/Icons/infinity.svg')" class="mr-2" width="16px">{{ $t('account_verified_block.list_item_one') }}
      </li>
      <li class="d-flex justify-content-start align-items-center mb-2">
        <img :src="require('@/assets/Connecting/Icons/infinity.svg')" class="mr-2" width="16px">{{ $t('account_verified_block.list_item_two') }}
      </li>
      <li class="d-flex justify-content-start align-items-center mb-2">
        <img :src="require('@/assets/Connecting/Icons/label.svg')" class="mr-2" width="16px">{{ $t('account_verified_block.list_item_three') }}
      </li>
      <li class="d-flex justify-content-start align-items-center mb-2">
        <img :src="require('@/assets/Connecting/Icons/speedo-gold.svg')" class="mr-2" width="16px">{{ $t('account_verified_block.list_item_four') }}
      </li>
    </ol>

    <div v-if="accountLimits" class="w-100 mx-5 mt-2 mt-md-5 mb-3">
      <b-button class="btn btn btn-primary px-4 mx-auto d-flex" v-b-modal.verified-account-action>{{ $t('account_verified_block.cta') }}</b-button>
    </div>

    <div v-if="accountType" class="w-100 mx-5 mt-2 mt-md-5 mb-3">
      <b-button class="btn btn btn-primary px-4 mx-auto d-flex" @click="submitUserTracker('verified')">{{$t('account_type_selection.get_verified.cta')}}</b-button>
    </div>

    <b-modal  v-if="accountLimits" id="verified-account-action" centered :hide-header=true :hide-footer=true body-class="p-0">
      <div class="d-flex flex-column flex-md-row mx-auto justify-content-center align-items-center">
        <div class="option mx-auto mb-3 mb-md-0 text-left d-flex flex-column align-items-center py-2">
          <div class="icon-shield mb-4 d-flex justify-content-center align-items-center">
            <img :src="require('@/assets/Connecting/shield-icon.svg')" class="" width="36px">
          </div>
          <div @click="$bvModal.hide('verified-account-action')" class="close position-absolute">
            <i class="fa-solid fa-xmark" aria-hidden="true"></i>
          </div>
          <div class="container">
            <h2 class="font-weight-bold mx-auto pb-3">{{$t('account_type_verified.verified.header')}}</h2>
            <hr class="w-100">
            <ul class="mt-4">
              <li class="mb-2">{{$t('account_type_verified.verified.list_item_one')}}</li>
              <li class="mb-2">{{$t('account_type_verified.verified.list_item_two')}}</li>
              <li class="mb-2">{{$t('account_type_verified.verified.list_item_three')}}</li>
              <li class="mb-2">{{$t('account_type_verified.verified.list_item_four')}}</li>
            </ul>
            <div class="mt-4 w-100 d-flex align-items-center justify-content-center">
              <a :href="appOrDesktopLink" target="_blank" rel="noopener" class="d-flex justify-content-center mb-4 btn btn-primary">
                {{ $t('account_type_verified.verified.continue') }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import apiService from '@/apiService'
import config from '../../config'

export default {
  name: 'VerificationBlock',
  data () {
    return {
      appOrDesktopLink: config.etn_deeplink.app
    }
  },
  props: {
    accountLimits: Boolean,
    accountType: Boolean
  },
  mounted () {
    if (this.$route.name === 'account-type') {
      if (this.$store.state.user.band === 1) {
        this.submitUserTracker('verified')
      } else {
        this.submitUserTracker('')
      }
    }
  },
  methods: {
    submitUserTracker (value = '') {
      apiService.submitUserTracker(1, value).then(response => {
        if (value === 'verified') {
          this.$emit('clicked', true)
          window.scrollTo(0, 0)
        }
      }).catch(() => { })
    }
  }
}
</script>

<style scoped lang="scss">
#verified-account-action {
  ol, li {
    list-style-type: auto;
  }
  .close {
    right: 10px;
    cursor: pointer;
  }
  .option {
    width: 100%;
    background-repeat: no-repeat;
    font-family: $header-font;
    font-size: 16px;
    white-space: pre-wrap;
    transition: all .2s ease-in-out;
    margin: 0 auto;
    &:nth-of-type(1) {
      background-image: url('~@/assets/Connecting/basic-shapes.svg');
      background-size: 44px;
    }
    &:nth-of-type(2) {
      background-image: url('~@/assets/Connecting/verified-shapes.svg');
      background-size: 64px;
    }
  }
}

.icon-shield {
  width: 72px;
  height: 72px;
  font-size: 2rem;
  border-radius: 50%;
  background: linear-gradient(137deg, #EBBB50 1%, #F6DA52 98%);
  margin: -45px auto 0 auto;
}

.verified {
  flex: 1;
  margin-top: 60px;
  background-image: url('../../assets/Connecting/geo-shapes-1.svg');
  background-size: 70px;
  background-repeat: no-repeat;
  background-color: white;
  border-radius: 12px;
  border: transparent;
  box-shadow: $connecting-btn-shadow;
  font-family: $header-font;
  font-size: 16px;
  white-space: pre-wrap;
  transition: all .2s ease-in-out;
  width: 100%;
  &:hover {
    box-shadow: $connecting-btn-hover-shadow;
    transform: scale(1.025);
    transition: all .2 ease-in-out;
  }
}

@include lg-up {
  .verified {
    margin-top: 0;
  }
}
</style>
