<template>
  <div class="bg-white pb-2 pb-md-5">
    <div class="d-none d-md-flex">
      <HeroCurved/>
    </div>
    <div class="main-container p-0">
      <div class="container pt-5 pb-4 position-relative bg-white content">
        <div v-if="!verified" id="choice">
          <h2 class="font-weight-bold">{{ $t('account_type_selection.title') }}</h2>
          <div class="">{{ $t('account_type_selection.subtitle') }}</div>
          <div class="d-flex flex-column flex-md-row mx-auto w-100 justify-content-center align-items-center account-options">
            <!--Basic-->
            <div class="option mr-md-5 mb-5 mb-md-0 text-left d-flex flex-column align-items-center px-5 py-2">
              <span class="mt-4">{{ $t('account_type_selection.basic.subheader') }}</span>
              <h2 class="font-weight-bold mx-auto mb-3">{{ $t('account_type_selection.basic.header') }}</h2>
              <hr class="w-75 px-3">
              <ol class="mt-2 p-0">
                <li class="d-flex justify-content-start align-items-center mb-2">
                  <img :src="require('@/assets/Connecting/Icons/speedo-grey.svg')" class="mr-2" width="16px">
                  <span v-if="$store.state.user.band === 2">$50</span>
                  <span v-if="$store.state.user.band === 3">$100</span>
                  <span v-if="$store.state.user.band === 4">$150</span>
                  {{$t('account_type_selection.basic.list_item_one')}}
                </li>
                <li class="d-flex justify-content-start align-items-center mb-2">
                  <img :src="require('@/assets/Connecting/Icons/speedo-grey.svg')" class="mr-2" width="16px">{{ $t('account_type_selection.basic.list_item_two') }}
                </li>
                <li class="d-flex justify-content-start align-items-center mb-2">
                  <img :src="require('@/assets/Connecting/Icons/speedo-grey.svg')" class="mr-2" width="16px">
                  <span v-if="$store.state.user.band === 2">$50</span>
                  <span v-if="$store.state.user.band === 3 || $store.state.user.band === 4">$100</span>
                  {{ $t('account_type_selection.basic.list_item_three') }}
                </li>
              </ol>
              <div class="py-4 mt-0 mt-md-5"  v-on:click="submitUserTracker()">
                <p class="w-100 d-flex mb-0 justify-content-center blue pointer">
                  {{$t('account_type_selection.basic.continue')}}
                </p>
              </div>
            </div>
            <!--verified-->
            <VerificationBlock :accountType="true" @clicked="emitVerified"/>
          </div>
          <div class="text-left mt-4 mt-md-5 px-5">
            <p class="small"><i>{{ $t('account_type_selection.terms_one') }}</i></p>
            <p class="small"><i>{{ $t('account_type_selection.terms_two') }}</i></p>
          </div>
        </div>
        <transition name="component-fade" mode="out-in">
          <div v-if="verified" id="verified">
            <h2 class="font-weight-bold">{{ $t('account_type_verified.title') }}</h2>
            <div class="">{{ $t('account_type_verified.subtitle') }}</div>
            <div class="d-flex flex-column flex-md-row mx-auto justify-content-center align-items-center account-options">
              <div class="option mx-auto mb-3 mb-md-0 text-left d-flex flex-column align-items-center py-2">
                <div class="icon mb-4 d-flex justify-content-center align-items-center">
                  <img :src="require('@/assets/Connecting/shield-icon.svg')" width="36px">
                </div>
                <h2 class="font-weight-bold mx-auto mb-3">{{ $t('account_type_verified.verified.header') }}</h2>
                <hr class="w-100">
                <ul class="mt-4 p-0">
                  <li class="mb-2">{{ $t('account_type_verified.verified.list_item_one') }}</li>
                  <li class="mb-2">{{ $t('account_type_verified.verified.list_item_two') }}</li>
                  <li class="mb-2">{{ $t('account_type_verified.verified.list_item_three') }}</li>
                  <li class="mb-2">{{ $t('account_type_verified.verified.list_item_four') }}</li>
                </ul>
                <div class="alert alert-primary small" role="alert">
                  <strong>{{ $t('account_type_verified.verified.tip_title') }}</strong>
                  <p class="m-0">{{ $t('account_type_verified.verified.tip') }}</p>
                </div>
                <div class="mb-4 w-100 d-sm-none d-flex flex-column align-items-center">
                  <a class="btn btn-electroneum w-100" :href="etnAppLink" rel="noopener">
                    {{ $t('account_type_verified.verified.continue') }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import HeroCurved from '../../components/Global/HeroCurved.vue'
import VerificationBlock from '../../components/MyAccount/VerificationBlock'
import config from '../../config'
import apiService from '../../apiService'

export default {
  name: 'AccountType',
  components: {
    HeroCurved,
    VerificationBlock
  },
  data () {
    return {
      verified: false,
      etnAppLink: config.etn_deeplink.app
    }
  },
  methods: {
    emitVerified (value) {
      if (value) {
        this.verified = true
      }
    },
    submitUserTracker () {
      apiService.submitUserTracker(1, 'basic').then(response => {
        this.$router.push({ name: 'accountProfile' })
      }).catch(() => {
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.pointer {
  cursor: pointer
}

.hero {
  background-image: $hero-mobile-shadow, url('~@/assets/WhatIsAnyTask/what-is-anytask-mobile.jpg');
  @include sm-up {
    background-image: url('~@/assets/WhatIsAnyTask/what-is-anytask-small.jpg');
  }
  @include md-up {
    background-image: url('~@/assets/WhatIsAnyTask/what-is-anytask-medium.jpg');
  }
  @include lg-up {
    background-image: url('~@/assets/WhatIsAnyTask/what-is-anytask-large.jpg');
  }
}

.option {
  a {
    &:hover {
      text-decoration: none !important;
    }
  }
}

.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .3s ease;
}
.component-fade-enter, .component-fade-leave-to {
  opacity: 0;
}

.content {
  margin-top: 0;
  .blue {
    color: $brand-sky;
  }
  #choice {
    .account-options {
      margin-top: 80px;
    }
  }
  #verified {
    .account-options {
      margin-top: 80px;
      .option {
        width: 100%;
        padding: 0 60px;
        background-image: url('~@/assets/Connecting/basic-shapes.svg');
        background-size: 44px;
      }
    }
    ol, li {
      list-style: disc;
      list-style-type: auto;
    }
  }
  .account-options {
    .icon {
      width: 72px;
      height: 72px;
      font-size: 2rem;
      border-radius: 50%;
      background: linear-gradient(137deg, #EBBB50 1%, #F6DA52 98%);
      margin: -42px auto 0 auto;
    }
    .option {
      width: 100%;
      background-repeat: no-repeat;
      border-radius: 12px;
      border: transparent;
      box-shadow: $connecting-btn-shadow;
      font-family: $header-font;
      font-size: 16px;
      white-space: pre-wrap;
      transition: all .2s ease-in-out;
      margin: 0 auto;
      max-width: 358px;
      &:nth-of-type(1) {
        background-image: url('~@/assets/Connecting/basic-shapes.svg');
        background-size: 44px;
      }
      &:nth-of-type(2) {
        background-image: url('~@/assets/Connecting/verified-shapes.svg');
        background-size: 64px;
      }
      &:hover {
        box-shadow: $connecting-btn-hover-shadow;
        transform: scale(1.025);
        transition: all .2 ease-in-out;
      }
    }
  }
}

@include md-up {
  #verified {
    .account-options {
      .option {
        width: 540px !important;
        padding: 0 80px;
      }
    }
  }
  .content {
    border-radius: 18px;
    margin-top: -5rem;
    .btn {
      &.w-100 {
        width: auto !important;
      }
    }
    .connecting-buttons {
      .btn {
        width: 47%;
      }
    }
  }
  .account-options {
    .option {
      width: auto !important;
    }
  }
}

@include lg-up {
  .content {
    .connecting-buttons {
      .btn {
        width: 36%;
      }
    }
  }
}

</style>
