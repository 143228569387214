<template>
  <div>
    <div class="mb-4">
      <h3 class="font-weight-bold text-left">{{ $t('job.create_edit.section_four.preview') }}</h3>
      <p>{{ $t('job.create_edit.section_four.preview_description') }}</p>
      <div class="text-center">
        <button class="btn btn-outline-primary" @click="showModal()">{{ $t('job.create_edit.section_four.preview_button') }}</button>
      </div>
    </div>
    <div class="mb-4">
      <h3 class="font-weight-bold text-left">{{ $t('job.create_edit.section_four.submit') }}</h3>
      <p>{{ $t('job.create_edit.section_four.submit_description') }}</p>
    </div>

    <div class="text-right pt-2 pb-2 d-flex flex-md-row-reverse flex-column">
      <button class="btn btn-primary ml-0 ml-md-2 mb-2 mb-md-0" @click="onSubmit()" style="z-index: 1">{{ $t('job.create_edit.section_four.submit_button') }}</button>
      <router-link :to="{name:'job-view'}" class="btn btn-dark ml-0 ml-md-2 mb-2 mb-md-0">{{ $t('job.create_edit.section_four.save_draft') }}</router-link>
      <button class="btn btn-link text-dark" @click="$emit('job-create-edit-back')">{{ $t('job.create_edit.button.back') }}</button>
    </div>
    <preview-job :job="job"></preview-job>
  </div>
</template>

<script>
import PreviewJob from './PreviewJob'
import ApiService from '../../../apiService'

export default {
  name: 'CreateEditSection4',
  components: { PreviewJob },
  props: {
    job: {
      type: Object,
      required: true
    },
    validationFailed: {
      type: Object,
      required: false,
      default () {
        return { messages: [], fields: [] }
      }
    }
  },
  mounted () {
    this.$tracker.createJobSection(this.$route.name === 'job-edit' ? 'editJob4' : 'createJob4')
  },
  methods: {
    showModal () {
      this.$bvModal.show('job-preview')
    },
    async onSubmit () {
      if (!this.checkCanSubmit) {
        return
      }

      this.$swal({
        title: this.$t('job.create_edit.section_four.submit_modal.title'),
        text: this.$t('job.create_edit.section_four.submit_modal.description'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('job.create_edit.section_four.submit_modal.button_confirm'),
        cancelButtonText: this.$t('job.create_edit.section_four.submit_modal.button_cancel'),
        showCloseButton: true,
        showLoaderOnConfirm: true,
        preConfirm: (result) => {
          if (result === true) {
            return ApiService.actionRequestReviewJob(this.job.hash).then().catch((error) => {
              if (error.response.status === 409) {
                this.$swal.showValidationMessage(
                  '<p class="mb-0">' + error.response.data.error_message + '</p>'
                )
              }
              return ''
            })
          }
        }
      }).then((result) => {
        if (result.value !== '' && result.dismiss == null) {
          this.$tracker.createJobSection(this.$route.name === 'job-edit' ? 'jobSubmitEdit' : 'jobSubmit')
          this.$emit('job-create-edit-save')
          this.$router.push({ name: 'job-view' })
        }
      })
    }
  },
  computed: {
    checkCanSubmit () {
      return !(this.job.title === '' || this.job.description === '' || this.job.price_from === null || this.job.price_to === null)
    }
  }
}
</script>

<style scoped lang="scss">
.border-gradient {
  position: relative;
}

.border-gradient::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 5px;
  padding: 3px;
  background: linear-gradient(to bottom, #f07f13, #FF1F40);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

</style>
